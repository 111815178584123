import React from "react"
import { graphql } from "gatsby"
import { Typography, Grid, Box } from "@material-ui/core"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import {
  SiteTitle,
  Section,
  RichText,
  BackButton,
  Copyright,
  MenuDrawer,
} from "../components"
import { dark } from "../theme/colors"

const BlendBackground = styled(BackgroundImage)`
  background-blend-mode: color;
`

export const KernwaardenPage = ({ data }) => {
  const { title, text, background } = data.contentfulKernwaardenPage
  const backgrounds = [`linear-gradient(${dark}, ${dark})`, background.fluid]

  return (
    <React.Fragment>
      <MenuDrawer />
      <SiteTitle title="Kernwaarden" />

      <Box
        display="flex"
        minHeight="100vh"
        flexDirection="column"
        overflow="hidden"
      >
        {/* Background image */}
        <BlendBackground fluid={backgrounds}>
          <Section
            slant="background"
            horizontalSpacing={3}
            verticalSpacing={10}
          >
            <BackButton to="../../" color="secondary" />
          </Section>
        </BlendBackground>

        {/* Rich Text Section */}
        <Section
          display="flex"
          flexGrow={1}
          horizontalSpacing={3}
          verticalSpacing={1.5}
          slant="dark"
        >
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={6} xl={6}>
              <Typography variant="h2" gutterBottom>
                {title}
              </Typography>
              <RichText>{text.json}</RichText>
            </Grid>
          </Grid>
        </Section>

        {/* Footer */}
        <Section color="dark" slantCenter noPaddingTop>
          <Copyright />
        </Section>
      </Box>
    </React.Fragment>
  )
}

export const query = graphql`
  query getKernwaardenPage {
    contentfulKernwaardenPage {
      title
      background {
        fluid(maxWidth: 1920, maxHeight: 1080, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      text {
        json
      }
    }
  }
`

export default KernwaardenPage
